.sender {
  background-color: #20a090;
  border-radius: 10px;
  border-bottom-right-radius: 0px;
  color: white;
}

.receiver {
  background-color: #f2f7fb;
  border-radius: 10px;
  border-top-left-radius: 0px;
}

.userName {
  font-size: 12px;
}

input[type='text'] {
  /* text-transform: uppercase; */
}

body {
  text-transform: uppercase;
}

textarea {
  /* text-transform: uppercase; */
}


.HighlightSearchText {
  font-weight: bold;
  background-color: yellow;
}